@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg {
  /* The image used */
  background-image: url("./images/dbg.png");
  height: 120px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.modalHeaderBg {
  background-image: url("./images/modal header img.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.signin {
  /* The image used */
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url("./images/sign2.png");
  height: 98vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
}
.reg {
  /* The image used */
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url("./images/sign1.png");
  height: 98vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 5px;
}

.mesh {
  background-image: url("./images/Mesh.svg");
  object-fit: cover;
  background-position: center;
}

.home-bg {
  background-color: #eef8ff;
  color: #184767;
  margin: 0%;
}
.nav-bg {
  background-image: linear-gradient(rgba(0, 0, 0 0.2), rgba(0, 0, 0, 0.8)),
    url("./images/dbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.botin {
  background-color: #127ec8;
  color: #eef8ff;
  padding: 15px 20px;
  text-align: center;
}

.botin2 {
  background-color: #ffffff;
  color: #127ec8;
  padding: 15px 20px;
  text-align: center;
}

.gmp {
  background-image: url("./images/gmp.png");
  background-size: cover;
  background-position: top;
}

.storeAd {
  background-image: url("./images/storeAd-bg.svg");
  background-size: cover;
}

.cardBg {
  background-image: url("./images/cardz.png");
  background-size: cover;
  height: 212px;
  background-repeat: no-repeat;
  background-position: top;
}
.storeBg {
  background-image: url("./images/storee.png");
  background-size: cover;
  height: 264.8px;
  background-repeat: no-repeat;
  position: relative;
}
.getStarted {
  background-color: #000000;
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fillRule='evenodd'%3E%3Cg fill='%23272626' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.footer-border {
  border-top: 1px solid #127ec820;
  margin-top: 20px;
  margin-left: 64px;
  margin-right: 64px;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #127ec820;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #127ec8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #127ec885;
}

.store-bg{
  background-image: linear-gradient(180deg, #9747ff90 0%, #9747FF90 100%) ,url("./images/shop.jpg");
  background-size: cover;
}

.option-spacing {
  margin-bottom: 0.5rem; /* Adjust this value for the desired spacing */
}

/* Receipt.css */

.container {
  padding: 20px;
}

.logo-image {
  height: 100px;
  width: 100px;
}

.online-banking {
  font-weight: bold;
  font-size: 24px;
  color: #007bff;
}

.transaction-receipt {
  font-size: 18px;
  color: #000;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd; /* Add border property */
}

.table-left {
  font-weight: bold;
  padding: 10px 20px;
  text-align: left;
  border: 1px solid #ddd; /* Add border property */
}

.table-right {
  padding: 10px 20px;
  text-align: left;
  border: 1px solid #ddd; /* Add border property */
}

.print-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.confirmation-logo {
  height: 150px;
  width: 150px;
}

.confirmation-text {
  font-size: 16px;
  color: #000;
}
/* collapse-styles.css */
.ReactCollapse--collapse {
  transition: height 300ms ease-in-out;
  height: 0;
  overflow: hidden;
}

.ReactCollapse--content {
  height: auto;
}

/* Define the loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the loader text */
.loader {
  animation: spin 1s linear infinite;
  font-size: 24px; /* You can adjust the font size */
}